<template>
  <div v-if="this.settings.set_my_available_dates !== undefined">
    <p class="tw-text-sm tw-text-black tw-mb-8 tw-flex tw-justify-between">
      {{
        this.settings.set_my_available_dates ?
            $t('vendor.calendar_settings_date_unavailable_label') + ' ' + $t('vendor.calendar_settings_date_unavailable_label1') :
            $t('vendor.calendar_settings_date_available_label') + ' ' + $t('vendor.calendar_settings_date_available_label1')
      }}
      <router-link :to="{ name: 'vendors.calendar.settings' }"
                   class="tw-w-10 tw-h-10 tw-bg-gray-n0 tw-flex tw-items-center tw-justify-center tw-rounded-xl tw-text-black">
        <span ref="anchorEl" @mouseover="showPopper"><settings-icon class="tw-h-3.5 tw-w-3.5"/></span>
      </router-link>

    </p>
           <Popper
            :is-open="show"
            :anchor-el="$refs.anchorEl"
            :on-close="hidePopper"
            >
            <aside id="popper-content">
              {{$t('vendor.calendar_settings')}}
            </aside>
          </Popper> 

    <div class="tw-flex tw-pb-4">
      <div class="tw-flex tw-items-center tw-w-full tw-mb-6">
        <div v-if="$refs.calendar">{{ $refs.calendar.title }}</div>
        <div class="tw-ml-auto tw-flex tw-justify-center tw-items-center">
          <v-menu
              bottom
              right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  outlined
                  v-bind="attrs"
                  v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <v-select
            v-show="type == 'week'"
            v-model="weekday"
            :items="weekdays"
            dense
            outlined
            hide-details
            label="weekdays"
            color="#2d2d2d"
            item-color="#2d2d2d"
            class="tw-flex-grow-0 tw-ml-6"
        ></v-select>
        <div class="tw-ml-4 tw-flex tw-items-center tw-space-x-3">
          <span @click="prev" class="tw-cursor-pointer">
            <chevron-left-icon class="tw-w-3.5 tw-h-3.5" />
          </span>
          <span @click="next" class="tw-cursor-pointer">
            <chevron-right-icon class="tw-w-3.5 tw-h-3.5" />
          </span>
        </div>
      </div>
      <spinner v-if="loading"></spinner>
    </div>
    <v-sheet height="600">
      <v-calendar
          ref="calendar"
          is-expanded
          :event-ripple="false"
          v-model="dates"
          :weekdays="weekday"
          :type="type"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :start="fromDate"
          :nav-visibility="true"
          :hide-header="false"
          :events="events"
          :event-color="getEventColor"
          @click:event="showEvent"
      >
        <template v-slot:day-label="{ day }">
          <span class="tw-inline-flex tw-w-6 tw-h-6 tw-text-xs tw-justify-center">{{ day }}</span>
        </template>

        <template v-slot:day-body="{ day, date, past }">
          <v-btn
              v-if="!past"
              :value="day" small
              class="tw-absolute tw-right-1 tw-top-1"
              @click="editDay($event,date)"
              icon
          >
            <edit2-icon class="tw-h-3 tw-w-3" />
          </v-btn>
        </template>

        <template v-slot:day="{ day, date, outside, past }">
          <v-btn
              v-if="!outside && !past"
              :value="day" small
              class="tw-absolute tw-right-1 tw-top-1"
              @click="editDay($event,date)"
              icon
          >
            <edit2-icon class="tw-h-3 tw-w-3" />
          </v-btn>
        </template>

      </v-calendar>

      <!-- Events view -->
      <v-dialog
          transition="dialog-bottom-transition"
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          max-width="600"
          content-class="tw-rounded-none"
      >
        <div class="tw-bg-white tw-p-10 tw-text-black">
          <div class="tw-flex tw-items-center tw-mb-11">
            <div class="tw-mr-4 tw-w-5 tw-h-5 tw-rounded-full" :class="selectedEvent.color"></div>
            <div>
              {{ selectedEvent.title }}
              / {{ selectedEvent.type }}
              / {{dateFormat(selectedEvent.start)}}
            </div>
          </div>
          <div>

            <!-- If Order -->
            <div v-if="selectedEvent.order">
              <div class="tw-mb-2">
                This date is booked.
              </div>

              <router-link
                      :to="{ name: 'vendor.bookings', query: {id: selectedEvent.order.id}}"
                      class="tw-text-red"
                      target="_blank"
              >View booking
              </router-link>
            </div>

            <!-- If Custom quote request -->
            <div v-if="selectedEvent.quote">
              <div class="tw-mb-2">
                This date is requested by quote request.
              </div>
              <router-link
                      :to="{ name: 'vendor.quotes', params: {id: selectedEvent.quote.id}}"
                      class="tw-text-red"
                      target="_blank"
              >View quote request
              </router-link>

            </div>

            <!-- If Vendor -->
            <div v-if="selectedEvent.user_id === selectedEvent.vendor_id">

              <!-- If Products -->
              <div v-if="selectedEvent.products">
                <div class="tw-mb-2">
                  <b class="tw-font-semibold">{{ selectedEvent.type }} products:</b>
                  <div v-for="(product,index) in selectedEvent.products" :key="index">
                    <p class="tw-text-sm tw-leading-relaxed tw-mb-0">
                      {{ product.name }}
                    </p>
                  </div>
                </div>
              </div>

            </div>

            <span v-html="selectedEvent.details"></span>
          </div>
          <div class="tw-grid tw-grid-cols-2 tw-gap-x-4">
            <button type="button" @click="selectedOpen = false"
                    class="tw-mt-6 tw-mr-2 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
              Cancel
            </button>
            <button type="button" @click="removeEvents(selectedEvent.id)"
                    class="tw-mt-6 tw-ml-2 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
              Delete
            </button>
          </div>
        </div>
      </v-dialog>

      <!-- Events add -->
      <v-dialog
          transition="dialog-bottom-transition"
          max-width="556"
          content-class="tw-rounded-none"
          v-model="selectedDayOpen"
          :close-on-content-click="false"
          :activator="selectedEditDayElement"
          offset-x
      >
        <div class="tw-bg-white tw-p-10 tw-text-black">
          <div class="tw-flex tw-items-center tw-mb-11">
            <a href="#" class="tw-text-current tw-mr-3.5" @click.prevent="selectedDayOpen = false"><x-circle-icon class="tw-h-5 tw-w-5" /></a>
            <span>{{ this.settings.set_my_available_dates ? ' Open a date' : ' Close a date' }}</span>
          </div>
          <div class="tw-mb-5">
            You are setting <span class="tw-text-red">{{dateFormat(item.dates.toString())}}</span>
            <span>{{ this.settings.set_my_available_dates ? ' available' : ' unavailable' }}</span> for others to book.
          </div>

          <div>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <div v-if="this.settings.set_my_available_dates && 1==2">
                <div><strong class="seccess--text">Check an option below to set type of availability</strong></div>
                <v-radio-group
                    v-model="item.is_available"
                    mandatory
                    row
                >
                  <v-radio
                      :value="true"
                  >
                    <template v-slot:label>
                      <div>
                        <strong>availability</strong>
                      </div>
                    </template>

                  </v-radio>
                  <!-- Ne znam zasto sam ostavio ranije opciju da mozes dodati i Not available i ako je settings all dates closed -->
                  <v-radio

                      :value="false"
                  >
                    <template v-slot:label>
                      <div>
                        <strong>unavailability</strong>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>


              <v-textarea

                  v-model="item.comment"
                  v-if="1==2"
                  name="input-7-4"
                  label="Comment"
                  :rules="[rules.required]"

              ></v-textarea>

              <v-autocomplete
                  v-if="this.settings.products_availability_check === true"
                  v-model="item.products"
                  :items="products"
                  item-value="id"
                  item-text="name"
                  label="Offerings"
                  multiple
                  chips
                  :clearable="true"
                  :hide-selected="true"
                  :deletable-chips="true"
                  auto-select-first
                  :rules="[rules.required_multi]"
                  :loading="this.ploading"
                  color="#000"
              >
                <template v-slot:append-item>
                  <div v-intersect="endIntersect"/>
                  <v-btn v-if="pmeta.current_page < pmeta.last_page"
                         x-small text @click="fetchProducts()"
                         :loading="ploading"

                         top
                         right
                  >Load more messages
                  </v-btn>
                </template>

              </v-autocomplete>
              <v-pagination
                  v-if="!loading && 1==2"
                  v-model="ppagination"
                  :length="pmeta.last_page"
                  :total-visible="pmeta.total"
              ></v-pagination>
            </v-form>
          </div>
          <div class="tw-grid tw-grid-cols-2 tw-gap-x-4">
            <button type="button" @click="selectedDayOpen = false"
                    class="tw-mt-6 tw-mr-2 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
              Cancel
            </button>
            <button type="button" @click="save()"
                    :disabled="!valid"
                    class="tw-mt-6 tw-ml-2 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
              Confirm
            </button>
          </div>
        </div>
      </v-dialog>
    </v-sheet>
  </div>
</template>


<script>

import { SettingsIcon, Edit2Icon, ChevronLeftIcon, ChevronRightIcon, XCircleIcon } from "vue-feather-icons";
import i18n from '@/i18n'
export default {
  middleware: ['auth', 'vendor'],
  components: {
    SettingsIcon,
    Edit2Icon,
    ChevronLeftIcon,
    ChevronRightIcon,
    XCircleIcon,
  },
  metaInfo(){
    return {
      title: i18n.t('calendar'),
      meta:[
        {
          name: 'description',
          content: i18n.t('description.vendors/calendar'),
        },
      ]
    }
  },
  data() {
    return {
      loading: false,
      show: false,
      dates: '',
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        {text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6]},
        {text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0]},
        {text: 'Mon - Fri', value: [1, 2, 3, 4, 5]},
        {text: 'Mon, Wed, Fri', value: [1, 3, 5]},
      ],
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      focus: '',
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      settings: [],
      products: [],
      pmeta: [],
      ppagination: 0,
      ploading: false,
      availability: [],
      events: [],
      usedDates: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      item: {
        dates: [],
        comment: '',
        products: [],
        is_available: true

      },
      selectedEditDayElement: null,
      selectedDayOpen: false,
      rules: {
        required: value => !!value || 'Required.',
        required_multi: v => !!(v && v.length) || 'Item is required',
      },
      valid: true,
      lazy: true,
    }
  },
  mounted() {
    this.loadData();
  },
  watch: {
    settings: function () {
      this.item.is_available = this.settings.set_my_available_dates;
    },
    '$route': 'loadData',
    "ppagination": function () {
      // console.log("pegination");
      //this.fetchProducts();
      // this.endIntersect();
    },
  },
  computed: {

    json_dates() {
      return this.availability.map(t => ({
        dates: t.json_dates,
      }));

    },
    fromDate() {
      let d = new Date();
      let dd = d.getDate();
      let mm = d.getMonth() + 1;
      let yyyy = d.getFullYear();

      return yyyy + '-' + mm + '-' + dd.toString();
    }
  },
  methods: {
    showPopper () {
      this.show = !this.show
    },
    hidePopper () {
      this.show = false
    },    
    async endIntersect(entries, observer, isIntersecting) {
      if (this.pmeta.current_page < this.pmeta.last_page) {
        //console.log("endIntersect");
        await this.fetchProducts();
        console.log(isIntersecting);
      }

    },
    async loadData() {
      await this.fetchSettings();
      await this.fetchAvailability();
      await this.fetchProducts();
    },
    contain(date) {
      let d = new Date().toISOString().slice(0, 10);
      if (date <= d) return true;

      let index = this.usedDates.indexOf(date);

      return index !== -1;
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    updateRange({start, end}) {
      const events = []

      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0
        const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        const second = new Date(first.getTime() + secondTimestamp)

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        })
      }

      this.events = events
    },
    async save() {

      await this.$refs.form.validate();
      if (!this.valid) return;

      this.$http.post('/api/vendor/availability', this.item)
          .then(({data}) => {
            this.$toast.success(data.message, "Success")
          })
          .catch(({response}) => {
            console.log('error', response.data.errors);
          }).finally(() =>
              this.fetchAvailability(),
          this.selectedEditDayElement = null,
          this.item.dates = [],
          this.selectedDayOpen = false
      )

    },
    removeEvents(id) {

      this.$http.delete('/api/vendor/availability/' + id)
          .then(({data}) => {
            this.$toast.success(data.message, "Success", {timeout: 1000});
          }).catch(({data}) => {
        console.log('error', data.message);
        this.$toast.warning(data.message, "Caution", {timeout: 1000});
      }).finally(() =>
              this.fetchAvailability(),
          this.selectedElement = null,
          this.item.dates = [],
          this.selectedOpen = false
      )

    },
    fetchAvailability() {
      this.loading = true;
      this.$http.get('/api/vendor/availability')
          .then(({data}) => {
            this.availability = data.data
          })
          .catch(({response}) => {
            console.log('error', response.data.errors);
          }).finally(() => {
        this.getEvents();
        this.loading = false;
      })
    },
    fetchSettings() {
      this.loading = true;
      this.$http.get('/api/vendor/settings')
          .then(({data}) => {
            this.settings = data.data
          })
          .catch(({response}) => {
            console.log('error', response.data.errors);
          }).finally(() => {
        this.loading = false;
      })

    },
    fetchProducts() {

      this.ploading = true;
      this.loading = true;

      this.ppagination = this.ppagination + 1;
      const params = {
        "page": this.ppagination ? this.ppagination : this.$route.query.page,
        "filter[is_published]": true,
        "filter[type]": "Package,Product",
      };
      let uri = '/api/vendor/products';
      this.$http.get(uri, {params})
          .then(({data}) => {
            this.products = this.products.concat(data.data),
                this.pmeta = data.meta
          })
          .catch(({response}) => {
            console.log('error', response.data.errors);
          }).finally(() =>
              this.ploading = false,
          this.loading = false
      )


    },
    getEvents() {
      //console.log("events....");
      this.loading = true;
      const events = [];
      this.usedDates = [];

      this.availability.forEach(element0 => {
        element0.dates.forEach(element => {

          const dateFormatted = element.datef;
          //const dateTimeFromLabel = (element.datef + ' 01:00').toString();
          //const dateTimeToLabel = (element.datef + ' 23:59').toString();

          this.usedDates = this.usedDates.concat(dateFormatted);

          let color = "tw-bg-green-100";
          let type = 'Available';
          let title = element0.user.name;


          if (this.settings.products_availability_check && element0.products.length > 0) {
            type = 'Available';
            color = "tw-bg-green-100";

          }

          if (element0.available === false) {

            // console.log(element0.user.name);

            if (element0.order) {
              type = 'Booked';
              title = element0.user.name;
              color = "tw-bg-gray-n0";
            } else if (element0.quote && element0.confirmed === true) {
              type = 'Quote offered';
              title = element0.user.name;
              color = "tw-bg-antiquewhite";
            } else if (element0.quote && element0.confirmed === false) {
              type = 'Quote requested';
              title = element0.user.name;
              color = "tw-bg-antiquewhite";
            } else {
              type = 'Unavailable';
              title = element0.user.name;
              color = "tw-bg-red-100";
            }


          }

          events.push({
            id: element0.id,
            name: type,
            user: element0.user.name,
            comment: element0.comment,
            type: type,
            title: title,
            start: dateFormatted,
            end: dateFormatted,
            allDay: true,
            color: color,
            available: element0.available,
            confirmed: element0.confirmed,
            order: element0.order,
            quote: element0.quote,
            products: element0.products,
            user_id: element0.user.id,
            vendor_id: element0.vendor.id
          })

        });
        this.loading = false;
      })

      this.events = events;
    },
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    async editDay(event, date) {

      await this.fetchSettings();

      this.item.dates = [];
      //this.item.dates = this.item.dates.concat(date);
      this.item.dates.push(date);

      const open = () => {
        this.selectedEditDayElement = event.target
        setTimeout(() => {
          this.selectedDayOpen = true
        }, 10)
      }

      if (this.selectedDayOpen) {
        this.selectedDayOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      event.stopPropagation()


    },
  }
}
</script>
